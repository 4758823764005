import { useHotSettings } from '@traba/hooks'
import { LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'

import { ScheduleInfoCard } from 'src/components/Schedule/ScheduleInfoCard'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'

interface Props {
  companyId: string
}

export const CompanySchedulesTab = ({ companyId }: Props) => {
  const { hotSettings } = useHotSettings()
  const { isLoading, shiftRequestParents } = useShiftRequestParents({
    companyId,
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (
    !hotSettings?.recurringSchedulesEnabled &&
    !hotSettings?.recurringSchedulesEnabledCompanies?.includes(companyId)
  ) {
    return (
      <Text variant="h4" mb={theme.space.xs}>
        Recurring schedule is not enabled for this company
      </Text>
    )
  }

  return (
    <>
      <Text variant="h4" mb={theme.space.xs}>
        Schedules
      </Text>
      {shiftRequestParents?.map((srp, index) => (
        <ScheduleInfoCard
          shiftRequestParent={srp}
          key={`${srp.shiftRequestParentId}_${index}`}
        />
      ))}
    </>
  )
}
