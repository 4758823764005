import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Text,
  WorkersManagementTable,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Shift,
  ShiftRequestParentWithShiftRequest,
  WorkerOnSchedule,
} from '@traba/types'
import { addDays } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ONE_MONTH_IN_DAYS, useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { cancelRecurringWorkerShift } from 'src/hooks/useRecurringWorkerShiftEdit'
import { useWorkersOnSchedule } from 'src/hooks/useWorkersOnSchedule'
import { Row } from '../base'
import { AddWorkerToScheduleModal } from './AddWorkerToSchedule/AddWorkerToScheduleModal'
import { RemoveWorkersFromScheduleModal } from './RemoveWorkersFromScheduleModal'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  companyId: string
}

export const ScheduleDetailsWorkerTab = ({
  shiftRequestParent,
  companyId,
}: Props) => {
  const { shiftRequestParentId, shiftRequests } = shiftRequestParent
  const navigate = useNavigate()
  const { rawShifts } = useCompanyShifts(
    {
      shiftRequestParentIds: [shiftRequestParentId],
      companyId: companyId,
      startAfter: new Date(),
      endBefore: addDays(new Date(), ONE_MONTH_IN_DAYS),
    },
    true,
  )
  const shifts = rawShifts?.pages.flatMap((page) => page?.data)
  const shiftRequestIdWithOneShift = shifts?.reduce((acc, shift) => {
    if (!shift.shiftRequestId) {
      return acc
    }
    if (!acc.has(shift.shiftRequestId)) {
      acc.set(shift.shiftRequestId, shift)
    }
    return acc
  }, new Map<string, Shift>())

  const {
    scheduledWorkers,
    isLoading,
    refetch: refetchWorkersOnSchedule,
  } = useWorkersOnSchedule(shiftRequestParentId, {})
  const [selectedWorkerIds, setSelectedWorkerIds] = useState<string[]>([])
  const [selectedShiftRequestId, setSelectedShiftRequestId] = useState<string>()
  const [showRemoveWorkersModal, setShowRemoveWorkersModal] = useState(false)
  const [showAddWorkersModal, setShowAddWorkersModal] = useState(false)

  const shiftRequestWorkerMap: {
    [key: string]: WorkerOnSchedule[]
  } = useMemo(() => {
    const shiftRequestWorkerMap: {
      [key: string]: WorkerOnSchedule[]
    } = {}
    scheduledWorkers?.forEach((worker) => {
      if (!worker.longTermShiftRequestId) {
        return
      }
      if (!shiftRequestWorkerMap[worker.longTermShiftRequestId]) {
        shiftRequestWorkerMap[worker.longTermShiftRequestId] = []
      }
      shiftRequestWorkerMap[worker.longTermShiftRequestId].push(worker)
    })
    return shiftRequestWorkerMap
  }, [scheduledWorkers])

  const onClickWorker = (workerId: string) => {
    navigate(`/workers/${workerId}`)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onCloseRemoveWorkersModal = () => {
    setShowRemoveWorkersModal(false)
  }

  const onCloseAddWorkersModal = () => {
    setSelectedWorkerIds([])
    setSelectedShiftRequestId('')
    setShowAddWorkersModal(false)
  }

  const onRemoveWorkers = (
    selectedWorkerIds: string[],
    shiftRequestId: string,
  ) => {
    setSelectedWorkerIds(selectedWorkerIds)
    setSelectedShiftRequestId(shiftRequestId)
    setShowRemoveWorkersModal(true)
  }
  const shiftRequestWithWorkers = Object.entries(shiftRequestWorkerMap)
  return (
    <>
      <Row gap={theme.space.xxs} justifyBetween>
        <Row alignEnd>
          <Text variant="h4">Workers</Text>
          <Text variant="h6" color={theme.colors.Grey60} ml={theme.space.xxs}>
            on this schedule
          </Text>
        </Row>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            setShowAddWorkersModal(true)
          }}
        >
          Add worker to schedule
        </Button>
      </Row>
      {shiftRequestWithWorkers.length === 0 && (
        <Text variant="h5" mt={theme.space.lg}>
          No workers on this schedule
        </Text>
      )}
      {shiftRequestWithWorkers.map(([_, workers]) => (
        <WorkersManagementTable
          scheduledWorkers={workers}
          onClickWorker={onClickWorker}
          isFromOpsConsole={false}
          onRemoveWorkers={onRemoveWorkers}
        />
      ))}
      <RemoveWorkersFromScheduleModal
        shiftRequestId={selectedShiftRequestId}
        workerIds={selectedWorkerIds}
        showRemoveWorkersModal={showRemoveWorkersModal}
        onCloseModal={onCloseRemoveWorkersModal}
        refetchWorkersOnSchedule={refetchWorkersOnSchedule}
        cancelRecurringWorkerShift={cancelRecurringWorkerShift}
      />
      <AddWorkerToScheduleModal
        handleClose={onCloseAddWorkersModal}
        isOpen={showAddWorkersModal}
        shiftRequestIdWithOneShift={
          shiftRequestIdWithOneShift ?? new Map<string, Shift>()
        }
        shiftRequests={shiftRequests}
        refetchWorkersOnSchedule={refetchWorkersOnSchedule}
      />
    </>
  )
}
