/**
 * TODO(polyphilz): This should be a shared hook for when we need this in the
 * Ops Console.
 */

import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'

export interface ShiftRequestParentsParams {
  companyId: string
}

const getShiftRequestParents = async ({
  companyId,
}: ShiftRequestParentsParams) => {
  try {
    const response = await trabaApi.get(
      `/shift-request-parents/company/${companyId}`,
    )
    return response.data
  } catch (error) {
    console.error("Error querying for company's shift request parents: ", error)
    throw error
  }
}

export const useShiftRequestParents = ({
  companyId,
}: ShiftRequestParentsParams) => {
  const {
    isLoading,
    isError,
    data: shiftRequestParents,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftRequestParentWithShiftRequest[], Error>({
    queryKey: ['shift-request-parents', companyId],
    queryFn: () =>
      getShiftRequestParents({
        companyId,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    shiftRequestParents,
    refetch,
  }
}
