import { ONE_HUNDRED_DOLLARS_MONEY_DTO } from '@traba/consts'
import {
  BreakType,
  Company,
  ForwardFillMax,
  InvitedWorkers,
  InvoiceChargeCycle,
  Money,
  RecurringSchedule,
  RequiredMultiShiftType,
  Schedule,
  Shift,
  ShiftNotificationStatus,
  ShiftPayType,
  ShiftRequest,
  ShiftRequestParent,
  ShiftSignupStatus,
  ShiftStatus,
  TierLevel,
} from '@traba/types'
import { anyToDate } from '@traba/utils'
import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  getDay,
  isBefore,
  set,
  setHours,
  startOfDay,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { getShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { validatePayRate } from 'src/modals/EditShiftModal/utils'
import { MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB } from 'src/utils/shiftFormUtils'

export const initialShiftRequest = (company: Company): CreateShiftRequest => {
  const tomorrow9AM = set(addDays(new Date(), 1), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  const tomorrow5PM = setHours(tomorrow9AM, 17)
  const INIT_SLOTS_REQUESTED = 1

  return {
    schedules: [
      {
        startTime: tomorrow9AM,
        endTime: tomorrow5PM,
        isRecurringSchedule: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    ],
    roleId: '',
    locationId: '',
    parkingLocationId: '',
    companyId: company.companyId,
    supervisorId: '',
    favoriteWorkersFirst: false,
    invitedWorkers: InvitedWorkers.ALL,
    slotsRequested: INIT_SLOTS_REQUESTED,
    minSlotsRequested: INIT_SLOTS_REQUESTED,
    trustAndSafetyFeeHourly: {
      amount: 50,
      currency: 'USD',
    },
    payRate: 13,
    hourlyRate: 13,
    payType: ShiftPayType.HOURLY,
    hasPaidBreaks: false,
    minimumPaidTime: 240,
    status: ShiftStatus.ACTIVE,
    employerName: company.employerName,
    allShiftsRequired: false,
    breakType: company.breakType || BreakType.AUTO_UNPAID,
    invoiceChargeCycle: InvoiceChargeCycle.WEEKLY,
    additionalEmails: [],
    forwardFillMax: ForwardFillMax.NONE,
    shiftCodesReceiverIds: [],
    shouldBypassValidateSchedule: false,
    signupStatus: ShiftSignupStatus.ALLOWED,
    notificationStatus: ShiftNotificationStatus.ALLOWED,
    requiredMultiShiftType: RequiredMultiShiftType.None,
    minimumAcceptedTier: TierLevel.UNPROVEN,
    unprovenWorkerThreshold: undefined,
    paymentType: undefined,
    paidBackupSlotsRequested: undefined,
    paidBackupPayAmount: undefined,
    numberOfUnits: undefined,
    shiftInvitations: undefined,
    scheduledBreaks: company.defaultBreaks ? [company.defaultBreaks] : [],
    enableDynamicOverbooking:
      (company.allowsDynamicOverbooking &&
        INIT_SLOTS_REQUESTED >= MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB) ||
      false,
    requireW9Authorization: company.requireW9Authorization,
    genderPreference: undefined,
  }
}

export const getShiftRequestForEditOrAdd = (
  shiftRequestParent: ShiftRequestParent | undefined,
  shiftRequest: ShiftRequest,
): CreateShiftRequest => {
  const {
    freeformAttributes: _freeformAttributes,
    requiredAttributes: _requiredAttributes,
    requiredCertifications: _requiredCertifications,
    requiredTrainingIds: _requiredTrainingIds,
    timezone: _timezone,
    updatedAt: _updatedAt,
    createdAt: _createdAt,
    unprovenWorkerCount: _unprovenWorkerCount,
    regionId: _regionId,
    geohash: _geohash,
    shiftCreatorId: _shiftCreatorId,
    shiftRequestId: _shiftRequestId,
    slotsFilled: _slotsFilled,
    ...remainingShiftRequest
  } = shiftRequest
  const schedules = remainingShiftRequest.schedules.map((schedule) => {
    return {
      ...schedule,
      startTime: anyToDate(schedule.startTime),
      endTime: anyToDate(schedule.endTime),
      recurringSchedule: {
        ...schedule.recurringSchedule,
        endDate: schedule.recurringSchedule?.endDate
          ? anyToDate(schedule.recurringSchedule.endDate)
          : undefined,
        freq: schedule.recurringSchedule?.freq ?? 'WEEKLY',
        interval: schedule.recurringSchedule?.interval ?? 1,
        repeatOn: schedule.recurringSchedule?.repeatOn ?? [],
      },
    }
  })
  return {
    ...remainingShiftRequest,
    schedules,
    requiredMultiShiftType:
      shiftRequest.requiredMultiShiftType ?? RequiredMultiShiftType.None,
    genderPreference: shiftRequest.genderPreference ?? undefined,
    shiftRequestParentTitle: shiftRequestParent?.title,
    hourlyRate: shiftRequest.hourlyRate ?? 0,
    minimumAcceptedTier: shiftRequest.minimumAcceptedTier ?? TierLevel.UNPROVEN,
    breakType: shiftRequest.breakType ?? BreakType.AUTO_UNPAID,
    requireW9Authorization: shiftRequest.requireW9Authorization ?? false,
  }
}

export function validateShiftCreationForm(
  createShiftRequest: CreateShiftRequest,
  minHourlyPayRate: number,
  paidBackupPayAmountMax: Money = ONE_HUNDRED_DOLLARS_MONEY_DTO,
): boolean {
  //Check that fields are defined
  if (
    !createShiftRequest.roleId ||
    !createShiftRequest.locationId ||
    !createShiftRequest.supervisorId
  ) {
    return false
  }

  if (
    createShiftRequest.overbookSlotsRequested &&
    createShiftRequest.overbookSlotsRequested <
      createShiftRequest.slotsRequested
  ) {
    return false
  }

  if (createShiftRequest.slotsRequested <= 0) {
    return false
  }

  if (
    isBefore(
      createShiftRequest.schedules[0].endTime,
      createShiftRequest.schedules[0].startTime,
    )
  ) {
    return false
  }

  if (
    !validatePayRate({
      payType: createShiftRequest.payType,
      payRate: createShiftRequest.payRate,
      minHourlyPayRate: minHourlyPayRate,
      numberOfUnits: createShiftRequest.numberOfUnits,
    })
  ) {
    return false
  }

  if (
    createShiftRequest.invoiceChargeCycle === InvoiceChargeCycle.GROUPED &&
    !createShiftRequest.parentInvoiceGroupId
  ) {
    return false
  }

  if (
    createShiftRequest.unprovenWorkerThreshold &&
    (createShiftRequest.unprovenWorkerThreshold > 1 ||
      createShiftRequest.unprovenWorkerThreshold < 0)
  ) {
    return false
  }

  if (
    (createShiftRequest.paidBackupSlotsRequested !== undefined &&
      createShiftRequest.paidBackupSlotsRequested !== 0 &&
      (!createShiftRequest.paidBackupPayAmount ||
        createShiftRequest.paidBackupPayAmount <= 0 ||
        createShiftRequest.paidBackupPayAmount >
          paidBackupPayAmountMax.amount)) ||
    (createShiftRequest.paidBackupPayAmount !== undefined &&
      createShiftRequest.paidBackupSlotsRequested === undefined)
  ) {
    return false
  }

  if (
    createShiftRequest.scheduledBreaks &&
    createShiftRequest.scheduledBreaks.length > 0 &&
    !!createShiftRequest.scheduledBreaks.find((b) => !b.count || !b.breakLength)
  ) {
    return false
  }

  return true
}

export function validateShiftCreationSchedule(
  selectedSingleShiftDates: Date[] | null,
  createShiftRequests: CreateShiftRequest[],
): boolean {
  // creation form needs to have at least one single date selected or it's recurring shift
  return (
    (!!selectedSingleShiftDates && selectedSingleShiftDates.length > 0) ||
    createShiftRequests.every((csr) => csr.schedules[0].isRecurringSchedule)
  )
}

export function populateCreateShiftRequestFromShiftRequestAndShift(
  shiftRequest: ShiftRequest,
  shift: Shift,
  createShiftRequest: CreateShiftRequest,
): CreateShiftRequest {
  const overwriteShiftRequestFields = Object.keys(shiftRequest).reduce(
    (acc, k) => {
      const key = k as keyof ShiftRequest

      if (key && key in createShiftRequest) {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(acc as any)[key] = shiftRequest[key]
      }
      return acc
    },
    {} as Partial<CreateShiftRequest>,
  )
  Object.keys(shift).reduce((acc, k) => {
    const key = k as keyof Shift
    if (key && key in createShiftRequest) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(acc as any)[key] = shift[key]
    }
    return acc
  }, overwriteShiftRequestFields)

  //Logic to get new dates for shift posting

  //To get new start Date. We use the businessStartTime if it exists to clear early arrival buffer.
  const shiftStartDayOfWeek = getDay(
    new Date(shiftRequest.schedules[0].startTime),
  )
  const actualStartTime = shift.businessStartTime
    ? shift.businessStartTime
    : shift.startTime

  const now = new Date()
  const today = utcToZonedTime(now, createShiftRequest.schedules[0].timeZone)
  const nextShiftStartDay = addDays(
    startOfDay(today),
    (7 - getDay(today) + shiftStartDayOfWeek) % 7,
  )
  const newStartTime = set(nextShiftStartDay, {
    hours: new Date(actualStartTime).getHours(),
    minutes: new Date(actualStartTime).getMinutes(),
  })
  const shiftLengthInMinutes = differenceInMinutes(
    new Date(shift.endTime),
    new Date(actualStartTime),
  )

  const schedules = shiftRequest.schedules.map((schedule) =>
    getScheduleForDuplication(
      schedule,
      newStartTime,
      addMinutes(newStartTime, shiftLengthInMinutes),
    ),
  )

  return {
    ...createShiftRequest,
    ...overwriteShiftRequestFields,
    schedules: schedules,
    status: ShiftStatus.ACTIVE,
  }
}

const getScheduleForDuplication = (
  schedule: Schedule,
  newStartTime: Date,
  newEndTime: Date,
) => {
  let recurringSchedule: RecurringSchedule | undefined = undefined

  if (schedule.recurringSchedule && schedule.isRecurringSchedule) {
    if (schedule.recurringSchedule.endDate) {
      const lengthOfReq = differenceInDays(
        new Date(schedule.recurringSchedule.endDate),
        new Date(schedule.startTime),
      )
      const newRecurringEndDate = addDays(newStartTime, lengthOfReq)
      recurringSchedule = {
        ...schedule.recurringSchedule,
        endDate: newRecurringEndDate,
      }
    } else {
      recurringSchedule = schedule.recurringSchedule
    }
  }
  return {
    ...schedule,
    startTime: newStartTime,
    endTime: newEndTime,
    recurringSchedule,
  }
}

export const getExistingShiftRequestFromSchedule = async (
  shiftRequestParentId: string,
) => {
  if (shiftRequestParentId) {
    const srp = await getShiftRequestParent(shiftRequestParentId)
    if (!srp) {
      throw Error('Shift request parent not found')
    }

    return srp?.shiftRequests[0]
  }
}
