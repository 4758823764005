import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { ShiftRequest } from '@traba/types'

export const createShiftRequestEdits = async (shiftRequestEdit: {
  edit: Partial<ShiftRequest> & {
    shiftRequestId: string
    editType: string
    cancellationSource?: string
    originalStartTime: Date
  }
}) => {
  try {
    const { edit } = shiftRequestEdit
    const {
      // These two are legacy fields that are still in shift request but not in shift request edit dto
      minSlotsRequested: _minSlotsRequested,
      hourlyRate: _hourlyRate,
      ...rest
    } = edit
    const response = await trabaApi.post(`shift-request-edits`, { edit: rest })
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}
