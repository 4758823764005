import { CircularProgress } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import {
  MediaUploader,
  Text,
  InfoTooltip,
  SearchSelect,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BillingUpdate,
  ShiftTag,
  CompanyCategory,
  BGCRequirement,
  RequiredMultiShiftType,
  Billing,
  Company,
  WorkerCertificationType,
  TierLevel,
  minimumTierMap,
  PaymentType,
} from '@traba/types'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { SetStateAction, useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { BackfillSettingsSelector } from 'src/components/BackfillSettingsSelector'
import { Button, Col, Input, Modal, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { ModalProps } from 'src/components/base/Modal/Modal'
import { IMenuItem } from 'src/components/base/Select/Select'
import Toggle from 'src/components/base/Toggle'
import { BGCRequirementSelector } from 'src/components/BGCRequirementSelector/BGCRequirementSelector'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { WeeklyPayWarning } from 'src/components/WeeklyPayWarning'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { TagSelector } from 'src/screens/ShiftDetailsScreen/components/TagSelector'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getEnumOptions } from 'src/utils/inputUtils'
import {
  minimumTierOptions,
  paymentTypeOptions,
} from 'src/utils/shiftFormUtils'
import BillingSettingsSection from './BillingSettingsSection'
import BreaksSettingsSection from './BreaksSettingsSection'
import { CompanyShiftBadges } from './CompanyShiftBadges'
import KioskModeSettingsSection from './KioskModeSettingsSection'

export type CompanySettingsModalProps = {
  company: Company
  billing?: Billing
  isLoadingCompany: boolean
  canApproveCompanies: boolean
  companySettingsModal: Omit<ModalProps, 'children'>
  patchCompany: UseMutateAsyncFunction<
    Company,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any, any>,
    Partial<Company>,
    Company
  >
  patchBilling: UseMutateAsyncFunction<
    Billing,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<any, any>,
    BillingUpdate,
    Billing
  >
}

export default function CompanySettingsModal({
  company,
  billing,
  isLoadingCompany,
  canApproveCompanies,
  companySettingsModal,
  patchCompany,
  patchBilling,
}: CompanySettingsModalProps) {
  const { showSuccess, showError } = useAlert()
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { handleUpload } = useFileUploader()
  /**
   * States
   */
  const [hasSameDayShiftCodes, setHasSameDayShiftCodes] = useState(
    company.hasSameDayClockInAndOutCodes ?? false,
  )
  const [companyIsApproved, setCompanyIsApproved] = useState(
    company.isApproved ?? false,
  )
  const [requireW9Authorization, setRequireW9Authorization] = useState(
    company.requireW9Authorization ?? false,
  )
  const [
    showAddressToNonWorkersForCompany,
    setShowAddressToNonWorkersForCompany,
  ] = useState(company.showAddressToNonWorkersForCompany ?? false)

  const [showNameToNonWorkersForCompany, setShowNameToNonWorkersForCompany] =
    useState(company.showNameToNonWorkersForCompany ?? false)

  const [allowPostingPayByUnit, setAllowPostingPayByUnit] = useState(
    company.allowPostingPayByUnit ?? false,
  )

  const [allowGenderPreference, setAllowGenderPreference] = useState(
    company.allowGenderPreference ?? false,
  )

  const [disableFenceLeavingPrompt, setDisableFenceLeavingPrompt] = useState(
    company.disableLeftFencePromptOverride ?? false,
  )
  const [showSearch, setShowSearch] = useState<boolean>(
    company.showSearch ?? false,
  )

  const [extraBGCRequirement, setExtraBGCRequirement] = useState(
    company.extraBGCRequirement,
  )
  const [hasPreShiftConfirmationRobocall, setHasPreShiftConfirmationRobocall] =
    useState(company.hasPreShiftConfirmationRobocall ?? true) // This defaults to TRUE
  const [minHourlyPayRate, setMinHourlyRate] = useState<number | undefined>(
    company.minHourlyPayRate,
  )
  const [minHourlyLoading, setMinHourlyLoading] = useState(false)

  const [hoursToClockInEarlyBy, setHoursToClockInEarlyBy] = useState<
    number | undefined | null
  >(company.hoursToClockInEarlyBy)
  const [hoursToClockInEarlyLoading, setHoursToClockInEarlyLoading] =
    useState(false)

  const [deleteCompanyOpen, setDeleteCompanyOpen] = useState(false)
  const [deleteCompanyLoading, setDeleteCompanyLoading] = useState(false)

  const [unprovenWorkerThreshold, setUnprovenWorkerThreshold] = useState<
    number | undefined
  >(company.unprovenWorkerThreshold)
  const [unprovenWorkerThresholdLoading, setUnprovenWorkerThresholdLoading] =
    useState(false)
  const [
    minutesAheadForShiftPostingLoading,
    setMinutesAheadForShiftPostingLoading,
  ] = useState(false)

  const [allowsDynamicOverbooking, setAllowsDynamicOverbooking] =
    useState<boolean>(company.allowsDynamicOverbooking ?? false)
  const [isFetchingCompany, setIsFetchingCompany] = useState(false)
  const [showRequiredMultiShiftToggle, setShowRequiredMultiShiftToggle] =
    useState<boolean>(company.showRequiredMultiShiftToggle ?? false)
  const [requiredMultiShiftType, setRequiredMultiShiftType] =
    useState<RequiredMultiShiftType>(
      company.requiredMultiShiftType ?? RequiredMultiShiftType.None,
    )

  const [paymentType, setPaymentType] = useState<PaymentType>(
    company.paymentType ?? PaymentType.AUTO,
  )
  const [minimumAcceptedTier, setMinimumAcceptedTier] = useState<TierLevel>(
    company.minimumAcceptedTier ?? TierLevel.UNPROVEN,
  )

  const [companyCategory, setCompanyCategory] =
    useState<CompanyCategory | null>(company.category ?? null)
  const [companyName, setCompanyName] = useState<string>(company.employerName)
  const [companyNameLoading, setCompanyNameLoading] = useState<boolean>(false)
  const [companyLogo, setCompanyLogo] = useState<string>(
    company.companyLogo || '',
  )
  const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(false)
  const [companyBaseMarkup, setCompanyBaseMarkup] = useState<
    number | undefined
  >(company.baseMarkup)
  const [companyBaseMarkupLoading, setCompanyBaseMarkupLoading] =
    useState<boolean>(false)
  const [companyCalculatedMarkup, setCompanyCalculatedMarkup] = useState<
    number | undefined
  >(company.calculatedMarkup)
  const [companyCalculatedMarkupLoading, setCompanyCalculatedMarkupLoading] =
    useState<boolean>(false)
  const [enabledCertifications, setEnabledCertifications] = useState<
    IMenuItem[]
  >(
    company.enabledCertifications?.map((cert) => {
      return { value: cert, label: cert }
    }) ?? [],
  )
  const [logoFile, setLogoFile] = useState<File | undefined>()
  const [upflowEnabled, setUpflowEnabled] = useState<boolean>(
    company.upflowEnabled ?? false,
  )
  const [minutesAheadForShiftPosting, setMinutesAheadForShiftPosting] =
    useState<number | undefined>(company.minutesAheadForShiftPosting)

  /**
   * Handlers
   */
  async function onChangeLogoFile(f: File | undefined) {
    if (!f) {
      return
    }
    setLogoFile(f)
    if (f) {
      setCompanyLogoLoading(true)
      const url = await handleUpload({
        fileType: FileType.COMPANY_IMAGES,
        media: f,
        userId: company.companyId,
      })
      setCompanyLogoLoading(false)
      setCompanyLogo(url)
    }
  }

  async function onDeleteFile(
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  ) {
    setFile(undefined)
  }

  async function handleCompanyPatchToggle(
    valueKey: keyof Company,
    value: boolean,
    setValue: (value: SetStateAction<boolean>) => void,
  ) {
    setIsFetchingCompany(true)
    const updatedValue = !value
    try {
      setValue(updatedValue)
      await patchCompany({ [valueKey]: updatedValue })
      showSuccess(
        `Successfully updated ${valueKey} to ${updatedValue}`,
        'Success',
      )
    } catch (err) {
      Sentry.captureException(err)
      setValue(value)
      showError(getErrorMessage(err), 'Error updating company')
    }
    setIsFetchingCompany(false)
  }

  async function handleSelectExtraBGCRequirement(
    selectedBGCRequirement: BGCRequirement,
  ) {
    const originalBGCRequirement = extraBGCRequirement
    setIsFetchingCompany(true)
    try {
      setExtraBGCRequirement(selectedBGCRequirement)
      await patchCompany({ extraBGCRequirement: selectedBGCRequirement })
      showSuccess(
        `Successfully updated company's "extraBGCRequirement" to: ${selectedBGCRequirement}`,
        'Success',
      )
    } catch (err) {
      Sentry.captureException(err)
      // if API Call fails, revert to original value in UI
      setExtraBGCRequirement(originalBGCRequirement)
      showError(getErrorMessage(err), 'Error')
    }
    setIsFetchingCompany(false)
  }

  async function handleTagSave(shiftTags: string[]) {
    setIsFetchingCompany(true)
    try {
      await patchCompany({
        shiftTags: _.compact(
          shiftTags.map((tag) => {
            switch (tag) {
              case 'VIP':
                return ShiftTag.VIP
              case 'NO_BACKFILL':
                return ShiftTag.NoBackfill
              case 'IGNORE':
                return ShiftTag.Ignore
              case 'NATIONAL':
                return ShiftTag.National
              case 'POD_ONE':
                return ShiftTag.Pod1
              case 'POD_TWO':
                return ShiftTag.Pod2
              case 'POD_THREE':
                return ShiftTag.Pod3
              case 'POD_FOUR':
                return ShiftTag.Pod4
              case 'POD_FIVE':
                return ShiftTag.Pod5
              case 'POD_SIX':
                return ShiftTag.Pod6
              case 'POD_SEVEN':
                return ShiftTag.Pod7
            }
          }),
        ),
      })
      showSuccess(
        `Successfully updated company's "shiftTags" to: ${shiftTags}`,
        'Success',
      )
    } catch (err) {
      Sentry.captureException(err)
      showError(getErrorMessage(err), 'Error')
    }
    setIsFetchingCompany(false)
  }

  async function handleDefaultRequiredMultiShiftTypeSelect(selection: string) {
    const previousType = requiredMultiShiftType
    const newType: RequiredMultiShiftType = ((
      type: string,
    ): RequiredMultiShiftType => {
      switch (type) {
        case 'NONE':
          return RequiredMultiShiftType.None
        case 'ALL_IN_REQUEST':
          return RequiredMultiShiftType.ALL_IN_REQUEST
        default:
          return RequiredMultiShiftType.None
      }
    })(selection)

    handleCompanySelect<RequiredMultiShiftType>(
      'requiredMultiShiftType',
      newType,
      previousType,
      setRequiredMultiShiftType,
    )
  }

  async function handleMinimumAcceptedTierSelect(selection: string) {
    const previousTier = minimumAcceptedTier
    const newTier: TierLevel =
      minimumTierMap[selection as TierLevel] || TierLevel.UNPROVEN

    handleCompanySelect<TierLevel>(
      'minimumAcceptedTier',
      newTier,
      previousTier,
      setMinimumAcceptedTier,
    )
  }

  async function handlePaymentTypeSelect(selection: PaymentType) {
    handleCompanySelect('paymentType', selection, paymentType, setPaymentType)
  }

  async function handleCompanySelect<
    T extends
      | string
      | number
      | Record<string, string | number>
      | null
      | string[],
  >(
    valueKey: keyof Company,
    selection: T,
    previousSelection: T,
    setter: React.Dispatch<React.SetStateAction<T>>,
  ) {
    setIsFetchingCompany(true)
    try {
      setter(selection)
      await patchCompany({ [valueKey]: selection })
      showSuccess(
        `Successfully updated company's "${valueKey}" to: ${selection}`,
        'Success',
      )
    } catch (err) {
      Sentry.captureException(err)
      setter(previousSelection)
      showError(getErrorMessage(err), 'Error')
    } finally {
      setIsFetchingCompany(false)
    }
  }

  async function handleCertificationChange(
    prev: IMenuItem[],
    selections: IMenuItem[],
  ) {
    setIsFetchingCompany(true)
    try {
      const enabledCertificationValues = selections.map((cert) => cert.value)
      setEnabledCertifications(selections)
      await patchCompany({ enabledCertifications: enabledCertificationValues })
      showSuccess(
        `Successfully updated company's enabledCertifications`,
        'Success',
      )
    } catch (err) {
      Sentry.captureException(err)
      showError(getErrorMessage(err), 'Error updating certifications')
      setEnabledCertifications(prev)
    }
    setIsFetchingCompany(false)
  }

  async function handleSaveBackfillSettings({
    allowsBackfill,
    lateTolerance,
  }: {
    allowsBackfill: boolean
    lateTolerance: number
  }) {
    setIsFetchingCompany(true)
    try {
      await patchCompany({
        backfillSettings: { allowsBackfill, lateTolerance },
      })
      showSuccess(
        `Successfully updated company's "backfillSettings" to: allowsBackfill: ${allowsBackfill} and lateTolerance: ${lateTolerance}`,
        'Success',
      )
    } catch (err) {
      Sentry.captureException(err)
      showError(getErrorMessage(err), 'Error')
    }
    setIsFetchingCompany(false)
  }

  async function handleSaveButton(
    values: Partial<Company>,
    setLoadingState: (value: SetStateAction<boolean>) => void,
  ) {
    try {
      setLoadingState(true)
      await patchCompany(values)
      const successMessage =
        'Successfully updated: ' +
        Object.entries(values)
          .map(([key, value]) => {
            if (key === 'defaultBreaks') {
              return `${key} to ${JSON.stringify(value)}`
            }
            return `${key} to ${value}`
          })
          .join(', ')

      showSuccess(successMessage, 'Successfully updated company')
    } catch (err) {
      Sentry.captureException(err)
      const failureMessage =
        'Error updating: ' +
        Object.entries(values)
          .map(([key, value]) => `${key} to ${value}`)
          .join(', ')
      showError(getErrorMessage(err), failureMessage)
    } finally {
      setLoadingState(false)
    }
  }

  async function deleteCompany() {
    setDeleteCompanyLoading(true)
    try {
      await trabaApi.delete(`companies/${company.companyId}`)
      companySettingsModal.handleClose()
      showSuccess(`${company.employerName} was deleted`)
    } catch (err: any) {
      showError(err.message, 'Error')
    } finally {
      setDeleteCompanyLoading(false)
    }
  }

  const defaultRequiredMultiShiftTypeMenuItems = [
    {
      value: RequiredMultiShiftType.None,
      label: RequiredMultiShiftType.None.toString(),
      secondaryLabel: 'No Required Multi-Shift acceptance',
    },
    {
      value: RequiredMultiShiftType.ALL_IN_REQUEST,
      label: RequiredMultiShiftType.ALL_IN_REQUEST.toString(),
      secondaryLabel: 'Require Multi-Shift acceptance',
    },
  ]
  const companyCategoryMenuItems = getEnumOptions(CompanyCategory)
  const enabledCertificationOptions = getEnumOptions(WorkerCertificationType)

  return (
    <>
      <Modal {...companySettingsModal} title="Company Settings">
        {isLoadingCompany || isLoadingHotSettings ? (
          <CircularProgress size="medium" />
        ) : (
          <>
            <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
              Company Toggles
            </Text>
            <Row justifyBetween>
              {canApproveCompanies && (
                <Toggle
                  label={'Company is Approved'}
                  buttonState={companyIsApproved}
                  runOnChange={() =>
                    handleCompanyPatchToggle(
                      'isApproved',
                      companyIsApproved,
                      setCompanyIsApproved,
                    )
                  }
                />
              )}
              <Toggle
                label={'Require W9 Authorization for all Shifts'}
                buttonState={requireW9Authorization}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'requireW9Authorization',
                    requireW9Authorization,
                    setRequireW9Authorization,
                  )
                }
              />
            </Row>
            <Row justifyBetween mt={theme.space.sm}>
              <Toggle
                label={'Has Same Day Clock in and out Codes'}
                buttonState={hasSameDayShiftCodes}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'hasSameDayClockInAndOutCodes',
                    hasSameDayShiftCodes,
                    setHasSameDayShiftCodes,
                  )
                }
              />
              <Toggle
                label={'Pre-shift confirmation Robocalls'}
                buttonState={hasPreShiftConfirmationRobocall}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'hasPreShiftConfirmationRobocall',
                    hasPreShiftConfirmationRobocall,
                    setHasPreShiftConfirmationRobocall,
                  )
                }
              />
            </Row>
            <Row justifyBetween mt={theme.space.sm}>
              <Toggle
                label={'Allow posting Pay By Unit'}
                buttonState={allowPostingPayByUnit}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'allowPostingPayByUnit',
                    allowPostingPayByUnit,
                    setAllowPostingPayByUnit,
                  )
                }
              />
              {hotSettings?.shouldUseNodeOverbooking && (
                <Toggle
                  label={'Allow Dynamic Overbooking'}
                  buttonState={allowsDynamicOverbooking}
                  runOnChange={() =>
                    handleCompanyPatchToggle(
                      'allowsDynamicOverbooking',
                      allowsDynamicOverbooking,
                      setAllowsDynamicOverbooking,
                    )
                  }
                />
              )}
            </Row>
            <Row justifyBetween mt={theme.space.sm}>
              <Toggle
                label={'Allow posting gender preference'}
                buttonState={allowGenderPreference}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'allowGenderPreference',
                    allowGenderPreference,
                    setAllowGenderPreference,
                  )
                }
              />
              <Toggle
                label={'Show Required Multi-Shift toggle in BizApp'}
                buttonState={showRequiredMultiShiftToggle}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'showRequiredMultiShiftToggle',
                    showRequiredMultiShiftToggle,
                    setShowRequiredMultiShiftToggle,
                  )
                }
              />
            </Row>
            <Row justifyBetween mt={theme.space.sm}>
              <Row>
                <Toggle
                  label={'Show Shift Address to Non-workers'}
                  buttonState={showAddressToNonWorkersForCompany}
                  runOnChange={() =>
                    handleCompanyPatchToggle(
                      'showAddressToNonWorkersForCompany',
                      showAddressToNonWorkersForCompany,
                      setShowAddressToNonWorkersForCompany,
                    )
                  }
                />
                <InfoTooltip
                  title={
                    "When enabled, workers who are not on this shift or haven't worked with this company before will also be able to see the shift full address."
                  }
                  tooltipProps={{
                    leaveDelay: 500,
                    placement: 'top',
                    arrow: true,
                  }}
                />
              </Row>
              <Row>
                <Toggle
                  label={'Show Company Name to Non-workers'}
                  buttonState={showNameToNonWorkersForCompany}
                  runOnChange={() =>
                    handleCompanyPatchToggle(
                      'showNameToNonWorkersForCompany',
                      showNameToNonWorkersForCompany,
                      setShowNameToNonWorkersForCompany,
                    )
                  }
                />
                <InfoTooltip
                  title={
                    "When enabled, workers who are not on this shift or haven't worked with this company before will also be able to see the company name."
                  }
                  tooltipProps={{
                    leaveDelay: 500,
                    placement: 'top',
                    arrow: true,
                  }}
                />
              </Row>
            </Row>
            <Row justifyBetween mt={theme.space.sm}>
              <Toggle
                label={'Disable prompting when worker leaves fence'}
                buttonState={disableFenceLeavingPrompt}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'disableLeftFencePromptOverride',
                    disableFenceLeavingPrompt,
                    setDisableFenceLeavingPrompt,
                  )
                }
              />
              <Toggle
                label={'Show worker search'}
                buttonState={showSearch}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'showSearch',
                    showSearch,
                    setShowSearch,
                  )
                }
              />
            </Row>
            <Row justifyBetween mt={theme.space.sm} mb={theme.space.sm}>
              <Toggle
                label={'Enable Upflow'}
                buttonState={upflowEnabled}
                runOnChange={() =>
                  handleCompanyPatchToggle(
                    'upflowEnabled',
                    upflowEnabled,
                    setUpflowEnabled,
                  )
                }
              />
            </Row>
            <Divider />
            <Text
              variant="h5"
              style={{
                marginBottom: theme.space.xs,
                marginTop: theme.space.xs,
              }}
            >
              Company Selectors
            </Text>
            <Row justifyBetween alignCenter>
              <Col>
                <Text variant="caption" mr={theme.space.xxxs}>
                  Company Category
                </Text>
                <Select
                  containerStyle={{
                    margin: `${theme.space.xs}px 0px`,
                    width: '200px',
                  }}
                  dropdownStyle={{ height: '48px' }}
                  menuItems={companyCategoryMenuItems}
                  value={companyCategory || ''}
                  handleSelect={(selection) => {
                    handleCompanySelect<CompanyCategory | null>(
                      'category',
                      selection as CompanyCategory,
                      companyCategory,
                      setCompanyCategory,
                    )
                  }}
                  label={'Category'}
                />
              </Col>
              <Col>
                <Text variant="caption" mr={theme.space.xxxs}>
                  Enabled Certifications
                </Text>
                <SearchSelect
                  multiple
                  options={enabledCertificationOptions}
                  selectedItems={enabledCertifications}
                  handleSelectMultiple={(selections) => {
                    handleCertificationChange(enabledCertifications, selections)
                  }}
                  onlyShowLabel
                  width={200}
                />
              </Col>
            </Row>
            <Row justifyBetween mt={theme.space.xs}>
              <Col>
                <Text variant="caption" mr={theme.space.xxxs}>
                  Default Required Multi-Shift Acceptance
                </Text>
                <Select
                  containerStyle={{
                    margin: `${theme.space.xs}px 0px`,
                    width: '200px',
                  }}
                  dropdownStyle={{ height: '48px' }}
                  menuItems={defaultRequiredMultiShiftTypeMenuItems}
                  value={requiredMultiShiftType}
                  handleSelect={handleDefaultRequiredMultiShiftTypeSelect}
                  label={'RMSA Type'}
                />
              </Col>
              <Col>
                <Text variant="caption" mr={theme.space.xxxs}>
                  Min Accepted Tier
                </Text>
                <Select
                  containerStyle={{
                    margin: `${theme.space.xs}px 0px`,
                    width: '200px',
                  }}
                  dropdownStyle={{ height: '48px' }}
                  label={'Tier'}
                  menuItems={minimumTierOptions}
                  handleSelect={handleMinimumAcceptedTierSelect}
                  value={minimumAcceptedTier || ''}
                  showEmptyOption
                />
              </Col>
            </Row>
            <Row justifyBetween mt={theme.space.xs}>
              <Col>
                <Text variant="caption" mr={theme.space.xxxs}>
                  Default Worker Payment Type
                  <InfoTooltip
                    title={
                      'Only applies to future shifts and hourly pay. Pay By Unit shifts are always manual.'
                    }
                    tooltipProps={{
                      leaveDelay: 500,
                      placement: 'top',
                      arrow: true,
                    }}
                  />
                </Text>
                <Select
                  containerStyle={{
                    margin: `${theme.space.xs}px 0px`,
                    width: '200px',
                  }}
                  dropdownStyle={{ height: '48px' }}
                  menuItems={paymentTypeOptions}
                  value={paymentType}
                  handleSelect={(val) =>
                    handlePaymentTypeSelect(val as PaymentType)
                  }
                  label={'Payment Type'}
                />
                {paymentType === PaymentType.AUTO_WEEKLY && (
                  <WeeklyPayWarning mb={theme.space.xs} />
                )}
              </Col>
            </Row>
            <Divider />
            <BGCRequirementSelector
              title={`What's the company's Background Check Requirement?`}
              selectedExtraBGCRequirement={extraBGCRequirement}
              handleSelectExtraBGCRequirement={handleSelectExtraBGCRequirement}
            />
            <Divider wrapperStyle={{ marginTop: theme.space.sm }} />
            <Text
              variant="h5"
              style={{
                marginBottom: theme.space.xs,
                marginTop: theme.space.sm,
              }}
            >
              Company Settings
            </Text>
            <Row mb={theme.space.sm}>
              <Row alignCenter mr={theme.space.sm}>
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Company Name
                </Text>
                <Input
                  width={'100%'}
                  value={companyName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCompanyName(e.target.value)
                  }
                />
                <Button
                  ml={theme.space.xs}
                  loading={companyNameLoading}
                  disabled={
                    !companyName || companyName === company.employerName
                  }
                  onClick={() =>
                    handleSaveButton(
                      { employerName: companyName },
                      setCompanyNameLoading,
                    )
                  }
                >
                  Save
                </Button>
              </Row>
            </Row>
            <Row mb={theme.space.sm}>
              <Row
                mr={theme.space.sm}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Logo
                </Text>
                <MediaUploader
                  id="company-logo-uploader"
                  label="Upload Logo"
                  file={logoFile}
                  onChange={onChangeLogoFile}
                  onDelete={() => onDeleteFile(setLogoFile)}
                  onError={(error) => {
                    showError(
                      error,
                      'File upload/delete error. Please try again.',
                    )
                  }}
                  fileType="image"
                  maxFileSizeMB={1}
                  marginTop={0}
                />
                <Button
                  ml={theme.space.xs}
                  loading={companyLogoLoading}
                  disabled={logoFile === undefined}
                  onClick={() =>
                    handleSaveButton({ companyLogo }, setCompanyLogoLoading)
                  }
                >
                  Save
                </Button>
              </Row>
            </Row>
            <Row mb={theme.space.sm}>
              <Row alignCenter mr={theme.space.sm}>
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Min Hourly Rate
                </Text>
                <NumberInput
                  width={'80px'}
                  value={minHourlyPayRate}
                  setValue={setMinHourlyRate}
                  decimals={2}
                  placeholder="eg. 14.50"
                  step={0.01}
                />
                <Button
                  ml={theme.space.xs}
                  loading={minHourlyLoading}
                  disabled={minHourlyPayRate === company.minHourlyPayRate}
                  onClick={() =>
                    handleSaveButton({ minHourlyPayRate }, setMinHourlyLoading)
                  }
                >
                  Save
                </Button>
              </Row>
              <Row alignCenter>
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Unproven Worker Threshold
                </Text>
                <NumberInput
                  width={'80px'}
                  value={unprovenWorkerThreshold}
                  setValue={setUnprovenWorkerThreshold}
                  decimals={2}
                  placeholder="eg. 0.5"
                  min={0}
                  max={1}
                  step={0.1}
                />
                <Button
                  ml={theme.space.xs}
                  loading={unprovenWorkerThresholdLoading}
                  disabled={
                    unprovenWorkerThreshold === company.unprovenWorkerThreshold
                  }
                  onClick={() =>
                    handleSaveButton(
                      { unprovenWorkerThreshold },
                      setUnprovenWorkerThresholdLoading,
                    )
                  }
                >
                  Save
                </Button>
              </Row>
            </Row>
            <Row alignCenter gap={theme.space.sm} mb={theme.space.sm}>
              <TagSelector
                onSave={handleTagSave}
                curTags={company.shiftTags || []}
              />
              <BackfillSettingsSelector
                allowsBackfill={company.backfillSettings?.allowsBackfill}
                lateTolerance={company.backfillSettings?.lateTolerance}
                onSave={handleSaveBackfillSettings}
              />
            </Row>
            <Row mb={theme.space.sm}>
              <Row alignCenter mr={theme.space.sm}>
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Early Clock In Override
                </Text>
                <NumberInput
                  width={'70px'}
                  value={
                    hoursToClockInEarlyBy === null
                      ? undefined
                      : hoursToClockInEarlyBy
                  }
                  setValue={setHoursToClockInEarlyBy}
                  placeholder="eg. 8"
                  step={1}
                  min={2}
                  max={24}
                />
                <Text variant="h6" style={{ marginLeft: theme.space.xs }}>
                  Hours
                </Text>
                <Button
                  ml={theme.space.xs}
                  loading={hoursToClockInEarlyLoading}
                  disabled={
                    hoursToClockInEarlyBy === company.hoursToClockInEarlyBy
                  }
                  onClick={() =>
                    handleSaveButton(
                      {
                        hoursToClockInEarlyBy:
                          hoursToClockInEarlyBy === undefined
                            ? null
                            : hoursToClockInEarlyBy,
                      },
                      setHoursToClockInEarlyLoading,
                    )
                  }
                >
                  Save
                </Button>
                <Text
                  variant="h6"
                  style={{
                    marginLeft: theme.space.xs,
                    marginRight: theme.space.xs,
                  }}
                >
                  Minimum Minutes Before Shift Start
                </Text>
                <NumberInput
                  width={'80px'}
                  value={minutesAheadForShiftPosting}
                  setValue={setMinutesAheadForShiftPosting}
                  decimals={0}
                  placeholder="eg. 120"
                  min={0}
                  max={120}
                  step={1}
                />
                <Button
                  ml={theme.space.xs}
                  loading={minutesAheadForShiftPostingLoading}
                  disabled={
                    minutesAheadForShiftPosting ===
                      company.minutesAheadForShiftPosting ||
                    minutesAheadForShiftPosting === undefined
                  }
                  onClick={() =>
                    handleSaveButton(
                      { minutesAheadForShiftPosting },
                      setMinutesAheadForShiftPostingLoading,
                    )
                  }
                >
                  Save
                </Button>
              </Row>
            </Row>
            <Row mb={theme.space.sm}>
              <Button
                onClick={() => setDeleteCompanyOpen(true)}
                variant={ButtonVariant.CANCEL}
                loading={deleteCompanyLoading}
              >
                Delete Company
              </Button>
            </Row>

            <Divider />

            <BillingSettingsSection
              billing={billing}
              patchBilling={patchBilling}
            />

            <Row my={theme.space.sm}>
              <Row alignCenter mr={theme.space.sm}>
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Base Markup
                </Text>
                <NumberInput
                  width={'80px'}
                  value={companyBaseMarkup}
                  setValue={setCompanyBaseMarkup}
                  decimals={4}
                  placeholder="eg. 0.5"
                  min={0}
                  max={1}
                  step={0.01}
                />
                <Button
                  ml={theme.space.xs}
                  loading={companyBaseMarkupLoading}
                  disabled={
                    !companyBaseMarkup ||
                    companyBaseMarkup === company.baseMarkup
                  }
                  onClick={() =>
                    handleSaveButton(
                      { baseMarkup: companyBaseMarkup },
                      setCompanyBaseMarkupLoading,
                    )
                  }
                >
                  Save
                </Button>
              </Row>
              <Row alignCenter mr={theme.space.sm}>
                <Text variant="h6" style={{ marginRight: theme.space.xs }}>
                  Calculated Markup
                </Text>
                <NumberInput
                  width={'80px'}
                  value={companyCalculatedMarkup}
                  setValue={setCompanyCalculatedMarkup}
                  decimals={4}
                  placeholder="eg. 0.5"
                  min={0}
                  max={1}
                  step={0.01}
                />
                <Button
                  ml={theme.space.xs}
                  loading={companyCalculatedMarkupLoading}
                  disabled={
                    !companyCalculatedMarkup ||
                    companyCalculatedMarkup === company.calculatedMarkup
                  }
                  onClick={() =>
                    handleSaveButton(
                      { calculatedMarkup: companyCalculatedMarkup },
                      setCompanyCalculatedMarkupLoading,
                    )
                  }
                >
                  Save
                </Button>
              </Row>
            </Row>

            <Divider />
            <KioskModeSettingsSection
              company={company}
              handleCompanySaveButton={handleSaveButton}
            />
            <Divider />
            <BreaksSettingsSection
              company={company}
              handleSaveButton={handleSaveButton}
            />

            <Divider wrapperStyle={{ marginBottom: theme.space.lg }} />
            <CompanyShiftBadges company={company} />

            {isFetchingCompany && (
              <CircularProgress
                size={'20px'}
                style={{ marginTop: 12, marginLeft: 6 }}
              />
            )}
          </>
        )}
      </Modal>
      <ConfirmationDialog
        open={deleteCompanyOpen}
        onConfirm={() => {
          deleteCompany()
        }}
        onClose={() => setDeleteCompanyOpen(false)}
        title="Delete Roster?"
        confirmButtonText="Delete"
        content={`Are you sure you want to delete ${company.employerName}?`}
      />
    </>
  )
}
